import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div className='px-4 bg-white'>
            <div className='text-center font-weight-bold py-2' style={{ fontSize: "20px" }}><u>Privacy Policy</u></div>
            <p style={{lineHeight: "1.8"}}>LionOrbit Tech Solutions Pvt. Ltd. is committed to protecting the privacy of our users. This Privacy Policy
                outlines the types of personal information we receive and collect when you use our mobile application ("Healthsaxis"),
                how we use and safeguard that information, and your choices regarding your information.
            </p>
            <div className='py-3'>
                <h6 className='py-2 font-weight-bold'>1. Information We Collect</h6>

                <p style={{lineHeight: "1.8"}} >Our Healthsaxis App may collect the following types of information:</p>
                <div className='py-2 px-3'>
                    <p style={{lineHeight: "1.8"}}><span className=''style={{fontSize:"18px"}}> . Personal Information:</span> We may collect personal information such as your name, email address, and other contact
                        information when you voluntarily provide it to us.</p>
                    <p style={{lineHeight: "1.8"}}><span className=''style={{fontSize:"18px"}}> . Health Data: :</span>Our App utilizes remote Photoplethysmography (rPPG) technology to gather health vitals or biomarkers. This may include heart rate, blood oxygen levels, and other relevant health metrics.</p>
                    <p style={{lineHeight: "1.8"}}><span className=''style={{fontSize:"18px"}}> . Usage Information:</span> We may collect information about your interactions with the App, such as the features you use, the pages you visit, and the frequency and duration of your activities.</p>
                </div>
                <h6 className='py-2 font-weight-bold'>2. Use of Information</h6>
                <p style={{lineHeight: "1.8"}}>We may use the collected information for the following purposes:</p>
                <div className='py-2 px-3'>
                    <p style={{lineHeight: "1.8"}}>. To provide and improve our services, including personalized health insights and risk scoring based on the collected health data.</p>
                    <p style={{lineHeight: "1.8"}}>. To communicate with you, respond to your inquiries, and provide customer support.</p>
                    <p style={{lineHeight: "1.8"}}>. To analyze trends, monitor usage patterns, and enhance the overall user experience.</p>
                </div>
                <h6 className='py-2 font-weight-bold'>3. Data Accuracy and Disclaimer</h6>
                <p style={{lineHeight: "1.8"}}>While we strive to provide accurate health information and insights, it is important to note the following:</p>
                <div className='py-2 px-3'>
                    <p style={{lineHeight: "1.8"}}>. The information provided by the App is intended for informational purposes only and should not be considered as medical advice or diagnosis.</p>
                    <p style={{lineHeight: "1.8"}}>. We do not guarantee the accuracy, completeness, or reliability of the health information provided by the App.</p>
                    <p style={{lineHeight: "1.8"}}>. Users should consult with a registered healthcare practitioner before making any healthcare decisions or changes based on the information provided by the App.</p>
                </div>
                <h6 className='py-2 font-weight-bold'>4. Data Security</h6>
                <p style={{lineHeight: "1.8"}}>We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</p>
                <h6 className='py-2 font-weight-bold'>5. Third-Party Services
                </h6>
                <p style={{lineHeight: "1.8"}}>Our App may integrate with third-party services, such as analytics providers or healthcare partners. These third parties may collect information about your use of the App, subject to their own privacy policies.</p>
                <h6 className='py-2 font-weight-bold'>6. Changes to Privacy Policy</h6>
                <p style={{lineHeight: "1.8"}}>. We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on the App.</p>
                <h6 className='py-2 font-weight-bold'>7. Contact Us</h6>
                <p style={{lineHeight: "1.8"}}>.If you have any questions or concerns about our Privacy Policy or data practices, please contact us at info@lionorbit.com.</p>
                <p style={{lineHeight: "1.8"}}> By using our App, you consent to the collection and use of your information as described in this Privacy Policy.</p>

            </div>
        </div>

    )
}

export default PrivacyPolicy