import React from 'react';
import styled from 'styled-components';
import logo from '../assets/images/logo.svg';
import image from '../assets/images/Group 46.svg'

const Img = styled.img`
  height: 50px;
  width:300px
  padding-top: 9px;
  pointer-events: none;
`;

const Logo = () => {
  return (
    <div className='text-white px-2 mt-1'>
      {/* <span className='px-2'><img src={image} style={{height:"20px",width:"20px",backgroundColor:"black"}} /></span> */}
      HealthsAxis</div>
  );
};

export default Logo;
