import React from 'react'
import { LuHeartPulse } from "react-icons/lu";
import { GoDotFill } from "react-icons/go";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import useMonitor from './../hooks/useMonitor';
import img1 from "../assets/images/1.svg"
import img2 from "../assets/images/2.svg"
import img3 from "../assets/images/3.svg"
import imgbp from "../assets/images/bp.svg"
import imgbreathing from "../assets/images/breathing.svg"
import imghead from "../assets/images/head.svg"
import imgheartrate from "../assets/images/heartrate.svg"
import imghemoglobin from "../assets/images/hemoglobin.svg"
import imglogo from "../assets/images/logo.svg"
import imgspo2 from "../assets/images/spo2.svg"
import imgstress from "../assets/images/stress.svg"
import imgwellness from "../assets/images/wellness.svg"


const Dashboard = ({dashboardData}) => {
    const navigate=useNavigate()

let obj=JSON.parse(localStorage.getItem("vitalSigns"))
let assigndata=Object.assign(dashboardData,obj)
let getdata={...assigndata}
console.log(getdata,"obj")
    
    let Cards = [
      {img:<img src={imgheartrate} style={{width:"38px",height:"38px"}}/>,name:"Heart Rate",
      level:getdata?.heartRate?.confidenceLevel,
      confidencelevel:"",val:getdata?.heartRate?.value!=undefined?`${getdata?.heartRate?.value}bpm`:"--bpm"},


      {img:<img src={imgbreathing} style={{width:"38px",height:"38px"}}/>,
      level:getdata?.breathingRate?.confidenceLevel,
      name:"Breathing Rate",confidencelevel:"",val:getdata?.breathingRate?.value!=undefined?`${getdata?.breathingRate?.value}brpm`:"--brpm"},
      
      
      
      {img:<img src={imgbp} style={{width:"38px",height:"38px"}}/>,
      level:getdata?.prq?.confidenceLevel,
      name:"Prq",confidencelevel:"",val:getdata?.prq?.value},
      
      
      {img:<img src={imgbp} style={{width:"38px",height:"38px"}}/>,
      level:getdata?.hrvSdnn?.confidenceLevel,
      name:"Hrv Sdnn",confidencelevel:"",val:getdata?.hrvSdnn?.value},
      
      
      {img:<img src={imgheartrate} style={{width:"38px",height:"38px"}}/>,
      level:getdata?.meanRri?.confidenceLevel
      ,name:"Mean Rri",confidencelevel:"",val:getdata?.meanRri?.value},
      
      
      {img:<img src={imgstress} style={{width:"38px",height:"38px"}}/>,
      name:"Stress Level",val:getdata?.stressLevel?.value},
      
      
      {img:<img src={imgstress} style={{width:"38px",height:"38px"}}/>,
      name:"stressIndex",val:getdata?.stressIndex?.value},
    //   {img:<img src={imgspo2} style={{width:"38px",height:"38px"}}/>,name:"Spo2",val:getdata?.spo2?.value},
      {img:<img src={imgbp} style={{width:"38px",height:"38px"}}/>,name:"Blood Pressure",val:getdata?.bloodPressure?.value},
      {img:<img src={imgbp} style={{width:"38px",height:"38px"}}/>,name:"Oxygen Saturation",val:getdata?.oxygenSaturation?.value},
      {img:<img src={imgbp} style={{width:"38px",height:"38px"}}/>,name:"Sdnn",val:getdata?.sdnn?.value},
      {img:<img src={imgheartrate} style={{width:"38px",height:"38px"}}/>,name:"Rri",val:getdata?.rri?.value},
      {img:<img src={imgbp} style={{width:"38px",height:"38px"}}/>,name:"Rmssd",val:getdata?.rmssd?.value},
      {img:<img src={img1} style={{width:"38px",height:"38px"}}/>,name:"Sd1",val:getdata?.sd1?.value},
      {img:<img src={img1} style={{width:"38px",height:"38px"}}/>,name:"Sd2",val:getdata?.sd2?.value},
      {img:<img src={img2} style={{width:"38px",height:"38px"}}/>,name:"Pns Index",val:getdata?.pnsIndex?.value},
      {img:<img src={img2} style={{width:"38px",height:"38px"}}/>,name:"Pns Zone",val:getdata?.pnsZone?.value},
      {img:<img src={img3} style={{width:"38px",height:"38px"}}/>,name:"Sns Index",val:getdata?.snsIndex?.value},
      {img:<img src={img3} style={{width:"38px",height:"38px"}}/>,name:"Sns Zone",val:getdata?.snsZone?.value},
      {img:<img src={imgwellness} style={{width:"38px",height:"38px"}}/>,name:"Wellness Level",val:getdata?.wellnessLevel?.value},
      {img:<img src={imgwellness} style={{width:"38px",height:"38px"}}/>,name:"Wellness Index",val:getdata?.wellnessIndex?.value},
      {img:<img src={imgbp} style={{width:"38px",height:"38px"}}/>,name:"lfhf",val:getdata?.lfhf?.value},

    ]
    
    return (

        <div className='px-4'>
        <div className='row  py-2 font-weight-bold'style={{fontSize:"20px",backgroundColor:"black"}}>
            <div className='col-auto ' style={{cursor:"pointer"}} onClick={()=>navigate("/")}><FaArrowLeft size={20} color='white'/></div>
            <div className='col d-flex justify-content-center text-white'>HealthsAxis</div>
        </div>
        <img src=''></img>
        <div className='row '>
            {Cards?.map((v,i) => {
                return (
                    <div className='col-md-3 px-4 py-2' key={i}>
                        <div className=' border py-2 'style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} >
                            <div className='row  font-weight-bold'>
                                <div className='col-2  ml-2'>{v?.img}</div>
                                <div className='col-6' >{v?.name}</div>
                                <div className='col-3'>{`${v?.val!=undefined?v?.val:"--"}`}</div>
                            </div>
                            {v?.confidencelevel==""&&<div className='row px-2 mt-2'>
                            <div className='col-md-8'>Confidence level :{v?.level}</div>
                            {/* <div className='col-4 d-flex justify-content-end'><span className='mt-1'><GoDotFill color='yellow'/></span>Medium</div> */}
                            </div>}
                        </div>

                    </div>
                )
            })}
        </div>

        
        </div>

    )
}

export default Dashboard