import settings from '../assets/settings-hamburger.svg';
import React from 'react';
import styled from 'styled-components';

const Img = styled.img<{ disable: boolean }>`
  height: 20px;
  width: 22px;
  visibility: ${({ disable }) => disable && 'hidden'};
`;

const SettingsButton = ({ onClick, disable }) => {
  return (
    <div>
      <Img
      id="settingsButton"
      disable={disable}
      src={settings}
      onClick={onClick}

    />
    </div>
  );
};

export default SettingsButton;
